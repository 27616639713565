import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { IWixAPI } from '@wix/yoshi-flow-editor';

export interface TranslatedPricingDetails {
  price: string;
  cycle: string;
  duration: string;
  freeTrial: string;
  additionalFees: FormattedAdditionalFee[];
}

export type FormattedAdditionalFee = {
  name: string;
  amount: string;
};

type NavigateToSectionOptions = {
  appDefinitionId: string;
  sectionId: string;
  queryParams?: Object;
  state?: string;
};

type NavigationPolicy =
  | {
      type: 'url';
      /** URL to navigate to. Must be relative or start with base URL. */
      url: string;
    }
  | { type: 'section'; options: NavigateToSectionOptions }
  | { type: 'page'; pageId: string };

type SuccessStatusContent = {
  /** Override Thank you page title. */
  title?: string;
  /** Override Thank you page body. */
  message?: string;
  /** Override Thank you page CTA label. */
  cta?: string;
};

type SuccessStatusOptions = {
  /** Content of Thank You Page. */
  content?: SuccessStatusContent;
  /** Post Thank you page navigation policy. */
  navigation?: NavigationPolicy;
};

type BiOptions = {
  referralInfo: string;
  referralId?: string;
};

interface CheckoutOptions {
  /**
   * yyyy-MM-dd format string to restrict the earliest plan start day that can be selected.
   *
   * This is only a UI side restriction to help guide UoU.
   */
  minStartDate?: string;
  /**
   * yyyy-MM-dd format string to restrict the latest plan start day that can be selected.
   *
   * This is only a UI side restriction to help guide UoU.
   */
  maxStartDate?: string;
  /**
   * Thank you page configuration.
   *
   * @deprecated use {@link thankYouPage} field.
   * */
  successStatus?: SuccessStatusOptions;
  /** Thank you page configuration. */
  thankYouPage?: SuccessStatusOptions;
}

export type NavigateToCheckoutOptions = {
  planId: string;
  biOptions?: BiOptions;
} & CheckoutOptions;

export type NavigateToPackagePickerOptions = {
  /** Filter for a plan picker page. */
  planIds?: string[];
  /**
   * Override page title.
   *
   * Use this to provide better context for the use. Prefer to override `subtitle` instead of `title` if possible.
   */
  title?: string;
  /**
   * Override page subtitle.
   *
   * Use this to provide better context for the UoU. E.g. "Best plans for 'Hot Yoga class'".
   */
  subtitle?: string;
  biOptions?: BiOptions;
  /** Checkout configuration. */
  checkout?: CheckoutOptions;
};

export type PricingPageOptions = NavigateToPackagePickerOptions;

export interface PricingPlansPublicAPI {
  /**
   * Given plan entity, provides a record of various pricing texts.
   *
   * `cycle` and `freeTrial` fields may be empty string if plan is not recurring or has no free trial.
   */
  getTranslatedPricingDetails(plan: PublicPlan): TranslatedPricingDetails;

  /**
   * Navigate to main Pricing Plans page.
   *
   * @deprecated use the new {@link navigateToPricingPage} method.
   */
  navigateToPackagePicker(options: NavigateToPackagePickerOptions): void;

  /** Navigate to main Pricing Plans page. */
  navigateToPricingPage(options: PricingPageOptions): void;

  /** Navigate to Pricing Plans checkout. */
  navigateToCheckout(options: NavigateToCheckoutOptions): void;

  /**
   * Get current pricing page settings.
   *
   * Use it to implement custom pricing page and maintain proper integrations with other verticals.
   */
  getPricingPageOptions(): PricingPageOptions;
}

/**
 * Retrieves Pricing Plans public API using wixCodeApi.site.getPublicAPI.
 *
 * May throw if Pricing Plans is not installed in the site.
 */
export function getPricingPlansPublicAPI(wixCodeApi: IWixAPI): Promise<PricingPlansPublicAPI> {
  return wixCodeApi.site.getPublicAPI('1522827f-c56c-a5c9-2ac9-00f9e6ae12d3');
}
